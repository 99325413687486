import { api } from "@wisecards/utils";
import { defer, LoaderFunction, matchPath, redirect } from "react-router-dom";
import cache from "./cache";

export const appLoader: LoaderFunction = async ({ request }) => {
  const { pathname } = new URL(request.url);

  const isLoggedIn = document.cookie.match("loggedIn=true");
  const isLogin = matchPath("/login", pathname);
  const isRoot = matchPath("/", pathname);
  const isPublicCard = matchPath("/c/:id", pathname);

  if (isPublicCard) {
    return isLoggedIn ? {} : null;
  }

  if (!import.meta.env.DEV) {
    return redirect(import.meta.env.VITE_ORIGIN_SITE);
  }
  // proceed local-only

  if (isLoggedIn && (isLogin || isRoot)) {
    return redirect("/cards");
  }
  if (!isLoggedIn && !isLogin) {
    return redirect("/login");
  }
  if (isLoggedIn) {
    return {};
  }

  return null;
};

export const cardsLoader: LoaderFunction = async () => {
  return api("/cards", { action: "list" });
};

export const cardLoader: LoaderFunction = async ({ params }) => {
  //generated with email -> https://npm.runkit.com/uuid-by-string
  // org by domain 809863c8-793a-5493-ba91-d21f551c319a
  // tamsin.holland@prmf.co.uk
  // geoff.thorpe@prmf.co.uk
  const uids = [
    "04eb9649-3390-5e34-ae20-4bb2d544421b",
    "324011dc-c454-5640-8426-343b1397121a",
    "5e4f98a1-4b61-5f1c-a4d1-928a6b5f3357",
    "cf7ca444-c50d-5abd-b111-081cb3f49709",
    "48b5dd10-9cb9-53ae-9bd3-55a5bb2bdf1d",
    "099de16d-b0a9-501c-b29b-b3beac344b79",
    "3031f527-6cb6-504a-9c55-3863dbbe92d7",
    "cea4891e-ab83-5182-ac05-771d58e94d11",
    "ff94878c-f6ff-5459-a005-cc080edceec6",
    "bba80e0e-a44d-5b38-9c55-53fb1c3bd530",
    "a04cc607-3602-5137-9c20-1f257d452cd5",
    "04621c79-7b14-5d90-b4bf-4d649bab58c8",
    "8d40677c-b5e9-5405-a9a3-0872d24fde79",
    "bd01e226-02ff-5f39-a4a2-b51527611638",
    "404a4b6b-9d49-519f-aa49-c27c625eedd1",
    "483dd3bb-ab2f-5146-a80e-4ddb801aa733",
    "174963b7-e20b-5b0e-b395-e9ce65f1ba8e",
    "b63c0e30-d15c-5517-9e4a-4ca9a8446eb0",
    "fc3f687d-4ea8-5d68-b5c7-7508d1df672f",
    "033a6c30-fcac-5845-9d06-6786d0791c3d",
    "8e92e35b-6523-5c37-bc5d-1bbb72be0a42",
    "ad54c32d-9348-5dca-a046-839b83b5d1c1",
    "7034a18e-a4d8-53c0-bb5e-f8bc6ca7d461",
    "27116195-8876-5d6a-aa42-01d64b5b9d1e",
    "d04dbf3f-7aea-5a96-82c8-ac8db5f13ae9",
    "e7e02a57-cb02-5491-a46d-bc09e217bf2f",
    "4f2b0d77-b622-5281-950a-7bd1c4fa04e3",
    "751a9ead-5e2d-5a18-b196-24760bc160c8",
    "c9ef1657-7339-5734-a315-c157e4f79487",
    "e9c88e66-3dc6-54f7-8131-53559dfdcc96",
    "6e7b6eea-d0fc-51e2-a76c-ac7b486b3089",
    "7a09bc87-18a5-583f-b412-51a228097a50",
    "8b11658f-5849-5413-99fd-d5a89a326b6d",
    "ebdaa3cd-fc1d-5488-a6f0-3d38ede7cc83",
    "3aa3258e-6c2a-5acb-a46c-58e38bd74a85",
    "47f27efd-cc58-5507-a5a1-0ace70ee6484",
    "401c18ec-314a-544c-bbba-cad849fd38f8",
    "c3c379fa-4b7f-5f10-ba9d-dfeb813cfd32",
    "ce80ca16-fa1a-5b86-9fa6-1221efec08d0",
    "670acb68-febe-5f03-924d-1b24acf61279",
    "9d91c62e-6ead-542b-b26a-60a268e99b2e",
    "d890254b-ca84-55ba-9c02-059fdfd1baa0",
    "a68a81f3-c5db-5be1-8f4e-35e583867de4",
    "80c8444b-e91e-5f09-afc7-de08ab221ac2",
  ];
  if (uids.includes(params.id!)) {
    //let card = cache.get("card");
    //if (!card) {
    const card = import(`../../../shared/types/test/fixtures/${params.id}.json`)
      .then((d) => d.default)
      .then((r) => (cache.set("card", r), r));
    //}
    return defer({
      edit: cache.get("edit"),
      card,
    });
  }
  return {};
  //return await api("/cards", { action: "read", id: params.id });
};
