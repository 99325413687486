import { User } from "@wisecards/types";
import { Sidebar } from "@wisecards/ui-components";
import {
  ArrowRightOnRectangleIcon,
  ArrowsRightLeftIcon,
  ChartPieIcon,
  DocumentPlusIcon,
  HeartIcon,
  LifebuoyIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { toast, Toaster } from "react-hot-toast";
import { Outlet, useLoaderData, useNavigation } from "react-router-dom";

const AppLayout: React.FC = () => {
  const { state } = useNavigation();
  const user = useLoaderData() as User;

  useEffect(() => {
    if (state === "submitting") {
      toast.loading("Please wait…", { id: "loading" });
    } else {
      toast.dismiss("loading");
    }
  }, [state]);

  return (
    <div className="flex min-h-screen">
      <Toaster />
      {user && (
        <Sidebar
          links={[
            { label: "home", to: "/", icon: HeartIcon },
            { label: "team", to: "/team", icon: UserIcon },
            { label: "cards", to: "/cards", icon: DocumentPlusIcon },
            { label: "stats", to: "/stats", icon: ChartPieIcon },
            {
              label: "share",
              to: "/share",
              className: "mt-auto",
              icon: ArrowsRightLeftIcon,
            },
            { label: "support", to: "/support", icon: LifebuoyIcon },
            { label: "logout", to: "/login", icon: ArrowRightOnRectangleIcon },
          ]}
        />
      )}
      <main className="flex flex-1 flex-col">
        <Outlet />
      </main>
    </div>
  );
};

export default AppLayout;
