import { Address } from "@wisecards/types";

type Props = {
  fields: Address;
};

const CardModuleAddress: React.FC<Props> = ({ fields }) => {
  return (
    <div className="grid grid-cols-2 gap-4 border-b p-4 text-sm">
      <div className="overflow-hidden text-ellipsis">
        <h4 className="font-semibold">{fields.label}</h4>
        {[
          fields.line1,
          fields.line2,
          fields.city,
          fields.postcode,
          fields.country,
        ]
          .filter(Boolean)
          .map((l) => (
            <span key={l} className="block">
              {l}
            </span>
          ))}
      </div>
      <div className="space-y-4 text-right">
        {fields.tel?.map((t) => (
          <div key={t.label}>
            <h4 className="font-medium">{t.label}</h4>
            <a href={`tel:${t.number}`} rel="noopener noreferer">
              {t.number}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardModuleAddress;
