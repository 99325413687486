import $Lottie, { LottieComponentProps } from "lottie-react";
import { useEffect, useState } from "react";

type Props = Omit<LottieComponentProps, "animationData"> & {
  animation: "error-flat" | "error-outline";
};

const Lottie: React.FC<Props> = ({ animation, ...props }) => {
  const [src, setSrc] = useState<any>();

  useEffect(() => {
    import(`../assets/lotties/${animation}.json`).then((d) =>
      setSrc(d.default)
    );
  }, []);
  return <$Lottie animationData={src} {...props} />;
};

export default Lottie;
