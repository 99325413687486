import type { CardV1 as CardData } from "@wisecards/types";
import { Transition } from "@headlessui/react";
import { MoonIcon } from "@heroicons/react/24/outline";
import cx from "classnames";
import { useEffect, useState } from "react";

type Props = {
  data?: CardData;
  editMode?: boolean;
  onFlip?: (isBack: boolean) => void;
};

type FieldProps = {
  className?: string;
  children?: string | React.ReactElement[];
  editMode?: boolean;
  placeholder?: string;
} & (
  | {
      as?: "div" | "h2" | "h3" | "h4";
    }
  | {
      as?: "a";
      href?: string;
      target?: string;
      rel?: string;
    }
);

const Field: React.FC<FieldProps> = ({
  as: Tag = "div",
  editMode,
  className,
  ...props
}) => {
  const isHidden = !props.children && !editMode;
  const isEmpty = !props.children && editMode;
  if (isHidden) {
    return null;
  }
  return (
    <Tag {...props} className={cx(className, isEmpty && "opacity-40")}>
      {props.children || props.placeholder}
    </Tag>
  );
};

const Card: React.FC<Props> = (props) => {
  const [showBack, setShowBack] = useState(false);
  const [data, setData] = useState<CardData>(props?.data || {});
  const showTabs = props.editMode || Boolean(data.links?.length);

  useEffect(() => {
    if (props.data) setData(props.data);
  }, [props.data]);

  useEffect(() => {
    props.onFlip?.call(null, showBack);
  }, [showBack]);

  useEffect(() => {
    if (!props.editMode && !showTabs) setShowBack(false);
  }, [props.editMode]);

  return (
    <div>
      <div className="my-4 flex items-center justify-center gap-4">
        {showTabs &&
          ["Contact", "Links"].map((s, i) => (
            <button
              key={s}
              className={cx(
                "px-4 py-1 text-sm",
                "rounded-full bg-indigo-100",
                !showBack && i === 0 && "bg-indigo-200",
                showBack && i === 1 && "bg-indigo-200",
                "hover:bg-indigo-200 "
              )}
              onClick={() => setShowBack(s === "Links")}
            >
              {s}
            </button>
          ))}
      </div>
      <div className="relative h-[600px] w-[320px]">
        <Transition
          show={!showBack}
          className="absolute top-0 left-0 flex h-full w-full flex-col overflow-hidden rounded-lg bg-white shadow-lg transition-transform"
          enterFrom="-rotate-y-90"
          enterTo="delay-150"
          leaveTo="rotate-y-90"
        >
          <div className="relative h-40 bg-gray-600 p-4">
            <div className="absolute top-8 right-8 h-24 w-24 rounded-full bg-amber-300" />
          </div>
          <div className="bg-amber-300 p-4">
            <Field
              as="h3"
              editMode={props.editMode}
              placeholder="John Doe"
              children={data.name}
            />
            <Field
              as="h2"
              editMode={props.editMode}
              placeholder="Acme Inc."
              children={data.org}
            />
            <Field
              as="h4"
              editMode={props.editMode}
              placeholder="Your role"
              children={data.role}
            />
          </div>
          <div className="flex flex-col gap-2 p-4 text-sm">
            <Field
              as="a"
              href={`mailto:${data.email}`}
              target="_blank"
              rel="noopener noreferrer"
              placeholder="email@domain.com"
              editMode={props.editMode}
              children={data.email}
            />
            <Field
              as="a"
              href={data.url}
              target="_blank"
              rel="noopener noreferrer"
              placeholder="https://example.com"
              editMode={props.editMode}
              children={data.url}
            />
          </div>
          <div className="mt-auto flex gap-6 border-t p-4 text-sm">
            <Field
              className="flex-1"
              children={data.address}
              placeholder="Address"
              editMode={props.editMode}
            />
            <Field
              className="flex-1 text-right"
              children={data.tel}
              placeholder="+44 20390239"
              editMode={props.editMode}
            />
          </div>
        </Transition>
        <Transition
          show={showBack}
          className={cx(
            "absolute top-0 left-0 h-full w-full",
            "flex flex-col justify-center gap-4 text-center",
            "rounded-lg bg-amber-300 shadow-lg transition-transform"
          )}
          enterFrom="rotate-y-90"
          enterTo="delay-150"
          leaveTo="-rotate-y-90"
        >
          {!data.links?.length && (
            <div className="flex flex-col items-center gap-4">
              <MoonIcon className="w-10 stroke-1" />
              <h2>It's quiet in here...</h2>
            </div>
          )}
          {data.links?.map((link) => (
            <a
              className="mx-4 block bg-white p-4"
              key={link.url}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.title || link.url}
            </a>
          ))}
        </Transition>
      </div>
    </div>
  );
};

export default Card;
