import { Defaults, Social } from "@wisecards/types";
import { SocialIcon } from "react-social-icons";
import cx from "classnames";
import { useCardModuleContext } from "./CardModuleContext";
import {
  PlusCircleIcon,
  QrCodeIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { QRCode } from "react-qrcode-logo";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

type Props = {
  defaults: Defaults;
  fields: Social;
};

const CardModuleSocial: React.FC<Props> = ({ fields, defaults }) => {
  const { isEditing } = useCardModuleContext();
  const [showQR, setShowQR] = useState(false);
  return (
    <div className={cx("p-4")} style={{ backgroundColor: defaults.bgColor }}>
      <ul className="flex items-center gap-4">
        {fields.urls?.map((u) => (
          <li key={u} className="relative">
            <SocialIcon
              key={u}
              rel="noreferer noopener"
              target="_blank"
              url={u}
              bgColor={fields.iconColor || defaults.color}
              className={cx(
                "!h-8 !w-8",
                fields.iconTheme === "square" && "[&_svg]:!rounded-sm"
              )}
              defaultSVG={
                /trustpilot/.test(u)
                  ? {
                      icon: "M38.948 37.782 41.876 47l-9.887-7.33L22.102 47l3.785-11.846L16 27.824l12.226.022L32.01 16l3.764 11.846H48l-9.887 7.308-.035.026-6.09 4.49 6.96-1.888Z",
                      mask: "M0,0V64H64V0H0Z M38.948 37.782 41.876 47l-9.887-7.33L22.102 47l3.785-11.846L16 27.824l12.226.022L32.01 16l3.764 11.846H48l-9.887 7.308-.035.026-6.09 4.49 6.96-1.888Z",
                      color: "#00B67A",
                    }
                  : undefined
              }
            />
            {isEditing && (
              <XCircleIcon className="absolute -top-2 -right-2 h-4 w-4 rounded-full bg-white" />
            )}
          </li>
        ))}
        <li>
          <button
            type="button"
            className={cx(
              "flex h-8 w-8 items-center justify-center",
              fields.iconTheme === "square" ? "rounded-sm" : "rounded-full"
            )}
            style={{ backgroundColor: fields.iconColor || defaults.color }}
            onClick={() => setShowQR(true)}
          >
            <QrCodeIcon className="h-6 w-6" />
          </button>

          <Transition appear show={showQR} as={Fragment}>
            <Dialog
              className="fixed inset-0 z-10 flex items-center justify-center"
              onClose={() => setShowQR(false)}
            >
              <Transition.Child
                as="div"
                className="fixed inset-0 bg-black/50 transition-opacity"
                enterFrom="opacity-0"
                leaveTo="opacity-0"
              />
              <Transition.Child
                as={Fragment}
                enterFrom="opacity-0 scale-95"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative rounded bg-white p-4 text-center transition-all">
                  <Dialog.Title className="absolute inset-x-0 -top-12 mx-auto table rounded bg-black px-4 py-2 text-sm font-semibold text-white">
                    Share this card
                  </Dialog.Title>
                  <QRCode
                    size={200}
                    value={location.href}
                    qrStyle="dots"
                    eyeRadius={5}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </Dialog>
          </Transition>
        </li>
        {isEditing && (
          <li>
            <button
              className="flex h-8 w-8 place-items-center rounded border-2 border-current text-gray-300"
              type="button"
              onClick={() => console.log("hello")}
            >
              <PlusCircleIcon className="h-6 w-8" />
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default CardModuleSocial;
