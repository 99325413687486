import { Lottie } from "@wisecards/components";
const NotFound: React.FC = () => {
  return (
    <div className="flex min-h-screen items-center justify-center">
      <div>
        <h2 className="text-center text-xl font-bold">It's a 404!</h2>
        <Lottie
          className="my-2 mx-auto block w-64"
          animation="error-flat"
          loop={false}
        />
      </div>
    </div>
  );
};

export default NotFound;
