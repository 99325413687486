import cx from "classnames";
import { Form, NavLink } from "react-router-dom";
import { SVGProps } from "react";

type Props = {
  links: Array<{
    label: string;
    to: string;
    icon: React.FC<SVGProps<SVGSVGElement>>;
    className?: string;
  }>;
};

const Sidebar: React.FC<Props> = (props) => {
  return (
    <div className="sticky top-0 flex max-h-screen flex-col gap-4 bg-white p-4">
      {props.links.map((l, i) =>
        "separator" in l ? (
          <span key={i} className="mt-auto" />
        ) : l.label === "logout" ? (
          <Form key={l.to} action={l.to} method="delete">
            <button type="submit">
              <l.icon className={cx("h-6 w-6 stroke-1")} />
            </button>
          </Form>
        ) : (
          <NavLink key={l.label} to={l.to} className={l.className}>
            {({ isActive }) => (
              <l.icon
                className={cx(
                  "h-6 w-6 stroke-1",
                  isActive && "stroke-2 text-indigo-500"
                )}
              />
            )}
          </NavLink>
        )
      )}
    </div>
  );
};

export default Sidebar;
