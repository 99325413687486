import { Defaults, Role } from "@wisecards/types";
import Field from "./Field";

type Props = {
  defaults: Defaults;
  fields: Role;
};

const CardModuleRole: React.FC<Props> = ({ fields, defaults }) => {
  return (
    <div className="p-4">
      <Field
        module="role"
        name="name"
        defaultValue={fields.name}
        type="text"
        as="h2"
        className="font-bold"
      />
      <Field
        className="mb-1"
        module="role"
        name="extra"
        defaultValue={fields.extra}
        type="text"
        as="h4"
      />
      <Field
        className="max-w-[270px] whitespace-pre-line"
        module="role"
        name="role"
        defaultValue={fields.role}
        type="text"
        as="h3"
        style={{ color: defaults.color }}
      />
    </div>
  );
};

export default CardModuleRole;
