import { routes } from "@wisecards/ui-router";
import { getRandomBgColor } from "@wisecards/utils";
import cx from "classnames";
import { Link } from "react-router-dom";

const CardTemplates: React.FC = () => {
  const templates = [
    {
      name: "Hello World",
      category: "General",
    },
    {
      name: "Foobar",
      category: "Technology",
    },
    {
      name: "Developer",
      category: "Technology",
    },
  ];

  const sections = templates.reduce<
    { name: string; items: { name: string; category: string }[] }[]
  >((p, c) => {
    const prev = p.find((s) => s.name === c.category);
    if (prev) {
      prev.items.push(c);
    } else {
      p.push({ name: c.category, items: [c] });
    }
    c.category;
    return p;
  }, []);

  return (
    <div className="p-8">
      <h1>Choose from our templates</h1>
      <div className="my-4 flex flex-col gap-4">
        {sections.map((section) => (
          <div key={section.name}>
            <h2 className="my-2 text-lg font-bold text-indigo-500">
              {section.name}
            </h2>
            <div className="flex gap-8">
              {section.items.map((item) => (
                <Link
                  to={routes.cardNew}
                  key={item.name}
                  state={{ template: item.name }}
                >
                  <div
                    className={cx(
                      "flex h-80 w-60 items-center justify-center rounded p-4 text-white shadow",
                      getRandomBgColor()
                    )}
                  />
                  <h3 className="mt-2">{item.name}</h3>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardTemplates;
