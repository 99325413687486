enum routes {
  login = "/login",
  cardList = "/cards",
  cardPresets = "/cards/presets",
  cardNew = "/cards/new",
  cardView = "/cards/:id",
  cardEdit = "/cards/:id/edit",
  cardPublic = "/c/:id",
}

export default routes;
