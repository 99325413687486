import { Address, Card } from "@wisecards/types";
import VCard from "vcard-creator";

type Data = Partial<{
  name: string;
  firstName: string;
  lastName: string;
  picture: string;
  role: string;
  email: string;
  url: string;
  social: string[];
  address: Omit<Address, "tel">;
  tel: Address["tel"];
}>;

export function cardToVCardData(card: Card) {
  return card.modules.reduce((p, m) => {
    if (!m.fields) return p;
    if (m.id === "header") {
      p.picture = m.fields.iconSmall;
    }
    if (m.id === "role") {
      p.name = m.fields.name;
      const names = p.name?.split(" ") || [];
      p.firstName = names[0];
      p.lastName = names.slice(1).join(" ");
      p.role = m.fields.role?.replace(/\n/g, " ").replace(/\s{2,}/g, " ");
    }
    if (m.id === "uri") {
      p.email = m.fields.email;
      p.url = m.fields.url;
    }
    if (m.id === "address") {
      const { tel, ...address } = m.fields;
      p.tel = tel;
      p.address = address;
    }
    if (m.id === "social") {
      p.social = m.fields.urls;
    }
    return p;
  }, {} as Data);
}

export function generateVCard(card: Card) {
  const data = cardToVCardData(card);
  const vcard = new VCard();

  data.firstName && vcard.addName(data.lastName, data.firstName);
  data.address?.label && vcard.addCompany(data.address?.label);
  data.picture && vcard.addPhotoURL(data.picture);
  data.role && vcard.addJobtitle(data.role);
  data.email && vcard.addEmail(data.email);
  data.tel?.forEach(
    (t) =>
      t.number && vcard.addPhoneNumber(t.number.replace(/\(|\)| /g, ""), "WORK")
  );
  data.address &&
    vcard.addAddress(
      undefined,
      data.address.line1,
      data.address.line2,
      data.address.city,
      undefined,
      data.address.postcode,
      data.address.country,
      "WORK"
    );
  data.url && vcard.addURL(data.url, "WORK");
  vcard.addURL(location.href, "WORK");
  data.social?.forEach((u) => {
    const url = new URL(u);
    const domain = url.host.replace("www.", "");
    vcard.addSocial(url.href, domain);
  });

  return {
    filename: `${data.name?.replace(/ /g, "_")}.vcf`,
    vcard: vcard.toString(),
  };
}
