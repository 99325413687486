import { Defaults, Details } from "@wisecards/types";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import { Disclosure } from "@headlessui/react";
import cx from "classnames";

type Props = {
  defaults: Defaults;
  fields: Details;
};

const CardModuleDetails: React.FC<Props> = ({ fields, defaults }) => {
  return (
    <Disclosure as="div" className="p-4">
      {({ open }) => (
        <>
          <Disclosure.Button
            className="flex w-full justify-between"
            style={{ color: defaults.color }}
          >
            <h3 className="text-sm font-semibold">{fields.label}</h3>
            <ChevronUpIcon
              className={cx("h-4 w-4", open && "rotate-180 transform")}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="my-2 whitespace-pre-line text-xs">
            {fields.text}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default CardModuleDetails;
