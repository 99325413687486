import cx from "classnames";

type Props = React.InputHTMLAttributes<HTMLInputElement>;

const Input: React.FC<Props> = (props) => {
  return (
    <label className="block bg-gray-50 pt-2 text-left">
      <span className="block px-2 text-xs text-indigo-500">{props.title}</span>
      <input
        className={cx(
          "mt-0 block w-full px-2 py-1",
          "border-0 border-b-2 border-indigo-300",
          "bg-transparent",
          "focus:border-indigo-500 focus:ring-0"
        )}
        {...props}
        type={props.type || "text"}
      />
    </label>
  );
};

export default Input;
