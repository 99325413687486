import {
  ArrowUturnRightIcon,
  ChevronUpDownIcon,
  CloudArrowUpIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import { cache } from "@wisecards/ui-router";
import cx from "classnames";
import { DraggableProvided } from "react-beautiful-dnd";
import { Form, useRevalidator } from "react-router-dom";
import { useCardModuleContext } from "./CardModuleContext";

type Props = {
  id: string;
  children: React.ReactElement;
  dragHandleProps: DraggableProvided["dragHandleProps"];
};

const CardModuleWrapper: React.FC<Props> = (props) => {
  const { revalidate } = useRevalidator();
  const { isEditing, isDragging } = useCardModuleContext();

  return (
    <div
      className={cx(
        "group relative",
        isEditing &&
          "z-10 rounded outline outline-dotted outline-offset-2 outline-blue-500"
      )}
    >
      <div className="absolute -right-14 top-0 z-10 hidden rounded bg-gray-400/50 p-1 text-white">
        {isEditing ? (
          <div className="flex gap-1">
            <button key="submit" form={props.id} type="submit">
              <CloudArrowUpIcon className="h-4 w-4" />
            </button>
            <button
              type="button"
              onClick={() => {
                cache.delete("edit");
                revalidate();
              }}
            >
              <ArrowUturnRightIcon className="h-4 w-4" />
            </button>
          </div>
        ) : (
          <div className="flex gap-1">
            <button
              type="button"
              onClick={() => {
                cache.set("edit", props.id);
                revalidate();
              }}
            >
              <PencilSquareIcon className="h-4 w-4" />
            </button>
            <div {...props.dragHandleProps}>
              <ChevronUpDownIcon className="h-4 w-4" />
            </div>
          </div>
        )}
      </div>
      <Form
        id={props.id}
        method="patch"
        className={cx(
          //"group-first:rounded-t-lg group-last:rounded-b-lg",
          //"overflow-hidden",
          isDragging && "rounded-lg bg-white opacity-50"
        )}
      >
        <input type="hidden" name="id" value={props.id} />
        {props.children}
      </Form>
    </div>
  );
};

export default CardModuleWrapper;
