import { PlusIcon } from "@heroicons/react/24/outline";
import { Card, Defaults, Vcard } from "@wisecards/types";
import { generateVCard } from "@wisecards/utils";
import { useAsyncValue } from "react-router-dom";

type Props = {
  defaults: Defaults;
  fields: Vcard;
};

const CardModuleVCard: React.FC<Props> = ({ fields }) => {
  const card = useAsyncValue() as Card;
  const { vcard, filename } = generateVCard(card);

  const blob = new Blob([vcard], { type: "text/x-vcard" });
  const url = URL.createObjectURL(blob);

  return (
    <div className="border-t p-8 md:text-sm" style={{ color: fields.color }}>
      <a
        href={url}
        download={filename}
        className="mx-auto flex max-w-max items-center justify-center gap-2 rounded-full border border-current p-3"
      >
        <PlusIcon className="h-4 w-4" />
        <span>Add to Contacts</span>
      </a>
    </div>
  );
};

export default CardModuleVCard;
