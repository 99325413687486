import { PinEntry } from "@wisecards/ui-components";
import { useRef } from "react";
import { Form, useActionData, useSubmit } from "react-router-dom";

const SignIn: React.FC = () => {
  const data = useActionData() as { email?: string; token?: string } | null;
  const refPinEntry = useRef<PinEntry>(null);
  const submit = useSubmit();

  return (
    <div className="flex min-h-screen items-center justify-center">
      {data?.email ? (
        <div className="flex flex-col items-center gap-4 text-center">
          <h3 className="text-lg font-bold">Confirm login</h3>
          <p className="text-sm">
            Please enter the pin code you have received by email.
          </p>
          <PinEntry
            ref={refPinEntry}
            length={4}
            onComplete={(token) =>
              submit({ email: data.email!, token }, { method: "post" })
            }
            focus
            inputStyle={{
              border: "none",
              backgroundColor: "white",
              borderRadius: 4,
            }}
          />
          <footer className="mt-4 flex gap-2 text-xs">
            <button
              className="text-blue-500 underline"
              onClick={() => submit({ email: data.email! }, { method: "post" })}
            >
              Resend code
            </button>
            <span>or</span>
            <button
              className="text-blue-500 underline"
              onClick={() => submit({})}
            >
              Change email address
            </button>
          </footer>
        </div>
      ) : (
        <Form
          method="post"
          className="mx-auto my-24 flex max-w-sm flex-col items-center font-sans"
        >
          <h2 className="text-lg font-bold">Welcome to Wisecards</h2>
          <p className="text-sm">
            Please sign in or sign up with your email address.
          </p>
          <input
            name="email"
            type="email"
            className="my-4 w-full rounded border-none p-2 text-center"
            placeholder="hello@domain.com"
            required
          />
          <button className="button max-w-full">
            Sign in or create account
          </button>
        </Form>
      )}
    </div>
  );
};

export default SignIn;
