import { CardV2 } from "@wisecards/ui-components";
import { Link } from "react-router-dom";

const CardViewPublic: React.FC = () => {
  return (
    <div className="flex flex-1 flex-col place-content-between">
      <div className="md:my-auto">
        <CardV2 />
      </div>
      <footer className="hidden w-full bg-indigo-200 p-4 text-center text-sm">
        Get your free card at{" "}
        <Link className="text-indigo-500 underline" to="/login">
          wisecards.io
        </Link>
        .
      </footer>
    </div>
  );
};

export default CardViewPublic;
