import { CardV1 as Card } from "@wisecards/types";
import { TrashIcon } from "@heroicons/react/24/outline";
import cx from "classnames";
import { FormEvent, useCallback, useState } from "react";
import Input from "./Input";

type Props = {
  defaultValue?: Card;
  editLinks?: boolean;
  onChange: (value: Card) => void;
};

const CardEditor: React.FC<Props> = (props) => {
  const [links, setLinks] = useState<Card["links"]>(
    props.defaultValue?.links || []
  );

  const { name, org, role, email, country, address, tel, url } =
    props.defaultValue || {};

  const onChange = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const form = new FormData(e.currentTarget);
      const fields: Array<keyof Card> = [
        "org",
        "name",
        "role",
        "email",
        "country",
        "address",
        "tel",
        "url",
      ];
      const data = fields.reduce((p, c) => {
        const v = form.get(c)?.toString();
        if (c !== "links" && v) p[c] = v;
        return p;
      }, {} as Card);

      const linkNames = form.getAll("linkName");
      const linkUrls = form.getAll("linkUrl");
      const links = linkNames.map((title, i) => {
        return { title: title.toString(), url: linkUrls[i]?.toString() };
      });

      props.onChange({
        ...props.defaultValue,
        ...data,
        links,
      });
    },
    [props.defaultValue, links]
  );

  return (
    <form onChange={onChange}>
      <div className="flex flex-col gap-4">
        <div className={cx({ hidden: props.editLinks })}>
          <Input name="name" title="Full name" defaultValue={name} />
          <Input name="org" title="Company" defaultValue={org} />
          <Input name="role" title="Job title" defaultValue={role} />
          <Input
            name="email"
            title="Email address"
            type="email"
            defaultValue={email}
          />
          <Input name="country" title="Country" defaultValue={country} />
          <Input name="address" title="Address" defaultValue={address} />
          <Input
            name="tel"
            title="Phone number"
            type="tel"
            defaultValue={tel}
          />
          <Input name="url" title="Website" type="url" defaultValue={url} />
        </div>
      </div>
      <div className={cx({ hidden: !props.editLinks })}>
        <div className="flex items-center">
          <h2 className="flex-1 text-left">Link Tree</h2>
          <button
            type="button"
            className="button ml-auto"
            onClick={() => {
              setLinks(($links) => [
                { title: undefined, url: undefined },
                ...($links || []),
              ]);
            }}
          >
            Add link
          </button>
        </div>
        {links?.map((link, i) => (
          <div key={i} className="flex flex-col">
            <Input name="linkName" title="title" defaultValue={link.title} />
            <Input name="linkUrl" title="url" defaultValue={link.url} />
            <button
              type="button"
              className="button mx-0 self-end text-xs"
              onClick={() => {
                setLinks(($links) =>
                  $links?.filter((l) => !Object.is(link, l))
                );
              }}
            >
              Remove
              <TrashIcon className="w-4" />
            </button>
          </div>
        ))}
      </div>
    </form>
  );
};

export default CardEditor;
