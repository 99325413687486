import axios, { AxiosError } from "axios";
export { getRandomBgColor } from "./colors";
export * from "./vcard";

export async function api<R extends any>(
  path: string,
  data?: Record<string, unknown>
) {
  return axios
    .post<{ result: R }>(`/api/${path}`.replace("//", "/"), {
      data: data || {},
    })
    .then((r) => r.data.result)
    .catch(
      (err: AxiosError<{ error: { message: string; status: string } }>) => {
        throw new Error(err.response?.data.error.message || err.message);
      }
    );
}
