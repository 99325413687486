import { Defaults, Promo } from "@wisecards/types";

type Props = {
  defaults: Defaults;
  fields: Promo;
};

const origin = import.meta.env.VITE_ORIGIN_SITE;

const CardModulePromo: React.FC<Props> = ({ fields, defaults }) => {
  return (
    <div
      className="text-xs md:rounded-b-lg"
      style={{
        textAlign: fields.align,
        backgroundColor: defaults.bgColor,
        color: fields.color,
      }}
    >
      <a href={origin} target="_blank" className="inline-block p-4">
        powered by wisecards.io
      </a>
    </div>
  );
};

export default CardModulePromo;
