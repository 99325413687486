import type { CardV1 as CardData } from "@wisecards/types";
import { Card, CardEditor } from "@wisecards/ui-components";
import { routes } from "@wisecards/ui-router";
import { Transition } from "@headlessui/react";
import { useCallback, useState } from "react";
import {
  Link,
  useLocation,
  useParams,
  useRouteLoaderData,
  useSubmit,
} from "react-router-dom";

const CardEdit: React.FC = () => {
  const state = useLocation().state as { template: string } | null;
  const submit = useSubmit();
  const id = useParams().id;
  const [showEditor, setShowEditor] = useState(true);
  const [isBackVisible, setIsBackVisible] = useState(false);
  const cd = useRouteLoaderData("card") as CardData | undefined;
  const [cardData, setCardData] = useState<CardData>(
    cd || { template: state?.template }
  );

  const onSave = useCallback(async () => {
    submit(cardData as any, { method: "put" });
  }, [cardData]);

  return (
    <>
      <header className="flex items-center gap-4 border-b border-indigo-200 p-4">
        <h1 className="flex-1 font-bold">Template: {cardData.template}</h1>
        {!id && (
          <Link className="button" to={routes.cardPresets}>
            Back
          </Link>
        )}
        <button
          className="button min-w-[100px]"
          onClick={() => setShowEditor((e) => !e)}
        >
          {showEditor ? "Preview" : "Edit"}
        </button>
        <button className="button" onClick={onSave}>
          Finished
        </button>
      </header>
      <div className="flex flex-1">
        <div className="flex flex-1 items-center justify-center p-10">
          <Card
            data={cardData}
            editMode={showEditor}
            onFlip={setIsBackVisible}
          />
        </div>
        <Transition
          className="flex-1 bg-white p-8 text-center transition-transform"
          show={showEditor}
          appear
          enterFrom="translate-x-full"
          leaveTo="translate-x-full"
        >
          <CardEditor
            defaultValue={cardData}
            onChange={setCardData}
            editLinks={isBackVisible}
          />
        </Transition>
      </div>
    </>
  );
};

export default CardEdit;
