import cx from "classnames";
import { HTMLAttributes } from "react";
import { useCardModuleContext } from "./CardModuleContext";

type Props = {
  module?: string;
  editMode?: boolean;
  name: string;
  className?: string;
  defaultValue?: any;
  type: string;
  as: keyof HTMLElementTagNameMap;
  style?: HTMLAttributes<HTMLDivElement>["style"];
};
const Field: React.FC<Props> = (props) => {
  const { isEditing } = useCardModuleContext();
  return (
    <>
      {isEditing ? (
        <input
          defaultValue={props.defaultValue}
          className={cx(
            "font-inherit block w-full border-none p-0",
            props.className
          )}
          name={props.name}
          type={props.type}
          style={props.style}
        />
      ) : props.defaultValue ? (
        <props.as className={props.className} style={props.style}>
          {props.defaultValue}
        </props.as>
      ) : null}
    </>
  );
};

export default Field;
