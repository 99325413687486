import { Uri } from "@wisecards/types";

type Props = {
  fields: Uri;
};

const CardModuleUri: React.FC<Props> = ({ fields }) => {
  const { host } = new URL(fields.url || "/");
  return (
    <div className="space-y-1 border-b px-4 pb-4 text-sm">
      <a
        className="block"
        href={`mailto:${fields.email}`}
        rel="noopener noreferer"
      >
        {fields.email}
      </a>
      <a
        className="block"
        href={fields.url}
        target="_blank"
        rel="noopener noreferer"
      >
        {host}
      </a>
    </div>
  );
};

export default CardModuleUri;
