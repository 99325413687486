import { CardV1 as CardType } from "@wisecards/types";
import { Card } from "@wisecards/ui-components";
import { routes } from "@wisecards/ui-router";
import { ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import { QRCode } from "react-qrcode-logo";
import { generatePath, Link, useRouteLoaderData } from "react-router-dom";

const CardView: React.FC = () => {
  const card = useRouteLoaderData("card") as CardType;
  const cardUrl = `${location.origin}/c/${card.id}`;

  return (
    <>
      <header className="flex items-center gap-4 border-b border-indigo-200 p-4">
        <h1 className="flex-1 font-bold">Your card is ready to share</h1>
        <Link className="button" to={routes.cardList}>
          Back
        </Link>
        <Link
          to={generatePath(routes.cardEdit, { id: card.id! })}
          className="button"
        >
          Edit
        </Link>
      </header>
      <div className="flex flex-col items-center justify-center p-8">
        <div className="m-10 flex items-center gap-10">
          {<Card data={card} />}
          <div className="w-full max-w-[250px] text-center text-sm">
            <div className="mx-auto my-4 table overflow-hidden rounded-lg bg-white">
              <QRCode value={cardUrl} qrStyle="dots" eyeRadius={5} />
            </div>
            <p>
              You can share your card by using the QR code above or URL below.
            </p>
            <label className="mx-auto my-2 flex items-center gap-4 rounded bg-indigo-200 p-2">
              <input
                className="-mr-4 flex-1 flex-1 bg-transparent text-center font-mono"
                defaultValue={cardUrl}
              />
              <ClipboardDocumentIcon className="h-4 w-4" />
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardView;
