import { api } from "@wisecards/utils";
import axios, { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import { ActionFunction, redirect } from "react-router-dom";
import cache from "./cache";

type APIError = AxiosError<{ error: { status: string; message: string } }>;

export const loginAction: ActionFunction = async ({ request }) => {
  if (request.method === "POST") {
    const form = await request.formData();
    const data = { email: form.get("email"), token: form.get("token") };

    if (!data.token) {
      await axios.post("/auth/signin", { data });
      return data;
    }
    return axios
      .post("/auth/verify", { data })
      .then(() => redirect("/cards"))
      .catch((d: APIError) => {
        const message = d.response?.data.error.message || "error";
        toast.error(message);
        return { email: data.email };
      });
  }
  if (request.method === "DELETE") {
    await axios.post("/auth/signout", { data: {} });
    return null;
  }
};

export const cardAction: ActionFunction = async ({ request }) => {
  const data = Object.fromEntries(await request.formData());

  if (request.method === "PATCH") {
    if (data.op === "move") {
      const cachedCard = cache.get("card");
      const modules = Array.from(cachedCard.modules);
      const [removed] = modules.splice(Number(data.from), 1);
      modules.splice(Number(data.to), 0, removed);
      const card = { ...cachedCard, modules };
      cache.set("card", card);
      return { card };
    }
    const { id, ...fields } = data;
    console.log("PATCH", { id, fields });
    return null;
  }

  delete data.links;
  const r = await api<{ id: string }>("/cards", {
    action: data.id ? "update" : "create",
    doc: data,
  });

  return redirect(`/cards/${r.id}`);
};
