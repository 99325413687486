import {
  appLoader,
  cardAction,
  cardLoader,
  cardsLoader,
  loginAction,
  routes,
} from "@wisecards/ui-router";
import {
  AppLayout,
  CardEdit,
  CardList,
  CardTemplates,
  CardView,
  CardViewPublic,
  NotFound,
  SignIn,
} from "@wisecards/ui-screens";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  Outlet,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./index.css";

console.info(`v${import.meta.env.VITE_PKG_VERSION} (${import.meta.env.MODE})`);

createRoot(document.getElementById("app")!).render(
  <RouterProvider
    router={createBrowserRouter(
      createRoutesFromChildren(
        <Route loader={appLoader} element={<AppLayout />}>
          <Route
            action={loginAction}
            path={routes.login}
            element={<SignIn />}
          />
          <Route path={routes.cardList} element={<Outlet />}>
            <Route index loader={cardsLoader} element={<CardList />} />
            <Route path={routes.cardPresets} element={<CardTemplates />} />
            <Route
              path={routes.cardNew}
              action={cardAction}
              element={<CardEdit />}
            />
            <Route path={routes.cardView} id="card" loader={cardLoader}>
              <Route index element={<CardView />} />
              <Route
                path={routes.cardEdit}
                action={cardAction}
                element={<CardEdit />}
              />
            </Route>
          </Route>
          <Route
            path={routes.cardPublic}
            loader={cardLoader}
            action={cardAction}
            //errorElement={<NotFound />}
            element={<CardViewPublic />}
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      )
    )}
  />
);
