import { createContext, useContext } from "react";
import { DraggableStateSnapshot } from "react-beautiful-dnd";
import { useLoaderData } from "react-router-dom";

type Context = {
  isEditing: boolean;
  isDragging: boolean;
  prevId?: string;
  nextId?: string;
};

type Props = React.PropsWithChildren & {
  id: string;
  dragDropSnapshot: DraggableStateSnapshot;
  prevId?: string;
  nextId?: string;
};

const context = createContext<Context>({
  isEditing: false,
  isDragging: false,
});

export const CardModuleContext: React.FC<Props> = (props) => {
  const data = useLoaderData() as { edit?: string };
  const { isDragging } = props.dragDropSnapshot;
  return (
    <context.Provider
      value={{
        isEditing: data.edit === props.id,
        isDragging,
        prevId: props.prevId,
        nextId: props.nextId,
      }}
      children={props.children}
    />
  );
};

export const useCardModuleContext = () => useContext(context);
