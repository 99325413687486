import { Defaults, Header } from "@wisecards/types";
import { useCardModuleContext } from "./CardModuleContext";
import cx from "classnames";

type Props = {
  defaults: Defaults;
  fields: Header;
};

function bg(url?: string) {
  if (!url) return {};
  return { style: { backgroundImage: `url(${url})` } };
}

const CardModuleHeader: React.FC<Props> = ({ fields, defaults }) => {
  const { prevId, nextId } = useCardModuleContext();
  return (
    <div
      className={cx(
        "relative grid h-52 grid-rows-4 place-content-between px-4"
      )}
      style={{ color: defaults.color }}
    >
      <div
        className={cx(
          "absolute inset-0 bg-cover bg-left-bottom",
          !prevId && "md:rounded-t-lg"
        )}
        style={Object.assign(
          { backgroundColor: defaults.bgColor },
          fields.coverImg && { backgroundImage: `url(${fields.coverImg})` },
          //https://bennettfeely.com/clippy/
          nextId &&
            fields.heroMask && {
              clipPath: "polygon(0 0, 100% 0%, 100% 75%, 0% 100%)",
            }
        )}
      />
      <h1 className="relative col-start-3 row-start-1 py-2">{fields.title}</h1>
      {fields.iconLarge && (
        <div
          className="relative col-start-1 row-start-2 mb-6 h-28 w-28 rounded-full bg-gray-100 bg-cover"
          {...bg(fields.iconLarge)}
        />
      )}
      {fields.iconSmall && (
        <div
          className="relative col-start-3 row-start-3 mt-36 h-28 w-28 place-self-center rounded-full bg-cover"
          {...bg(fields.iconSmall)}
        />
      )}
    </div>
  );
};

export default CardModuleHeader;
