import { CardV1 as Card } from "@wisecards/types";
import { routes } from "@wisecards/ui-router";
import { getRandomBgColor } from "@wisecards/utils";
import { UserIcon } from "@heroicons/react/24/outline";
import cx from "classnames";
import { generatePath, Link, useLoaderData } from "react-router-dom";

const NewCard: React.FC = () => {
  return (
    <div
      className={cx(
        "flex items-center justify-center",
        "aspect-[8.6/5.4] w-80",
        "rounded bg-white p-4 shadow"
      )}
    >
      <Link to={routes.cardPresets} className="button">
        Create a new card
      </Link>
    </div>
  );
};

const CardList: React.FC = () => {
  const cards = useLoaderData() as Card[];
  if (!cards.length) {
    return (
      <div className="p-8">
        <h2>Let's get started</h2>
        <h3 className="mb-4">You don't have any cards yet</h3>
        <NewCard />
      </div>
    );
  }
  return (
    <div className="p-8">
      <div className="flex flex-wrap gap-4">
        {cards.map((card) => (
          <Link
            key={card.id}
            to={generatePath(routes.cardView, { id: card.id! })}
            className={cx(
              "relative",
              "flex items-end justify-end",
              "aspect-[8.6/5.4] w-80",
              "rounded p-4 shadow",
              getRandomBgColor()
            )}
          >
            <div className="absolute top-4 flex aspect-square w-20 items-center justify-center rounded-full bg-white">
              <UserIcon className="w-12 stroke-1 text-gray-500" />
            </div>
            <div className="text-right">
              <h4>Template: {card.template}</h4>
              <h3>Cardholder: {card.name}</h3>
            </div>
          </Link>
        ))}
        <NewCard />
      </div>
    </div>
  );
};

export default CardList;
