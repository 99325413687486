import type { Card, Defaults } from "@wisecards/types";
import cx from "classnames";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Await, useAsyncValue, useLoaderData } from "react-router-dom";
import { CardModuleContext } from "./CardModuleContext";
import CardModuleWrapper from "./CardModuleWrapper";

type CardModuleProps = { defaults: Defaults; fields: Record<string, any> };

const modules = import.meta.glob<React.FC<CardModuleProps>>(
  "./CardModule-*.tsx",
  { eager: true, import: "default" }
);

const CardModules: React.FC = () => {
  const cardData = useAsyncValue() as Card;
  const [card, setCard] = useState(cardData);

  const parts = card.modules.map((m, i, a) => {
    const Mod = modules[`./CardModule-${m.id}.tsx`] || (() => <b>!{m.id}</b>);
    return (
      <Draggable
        draggableId={m.id!}
        index={i}
        key={m.id}
        children={(provider, snapshot) => (
          <div ref={provider.innerRef} {...provider.draggableProps}>
            <CardModuleContext
              id={m.id!}
              dragDropSnapshot={snapshot}
              prevId={a[i - 1]?.id}
              nextId={a[i + 1]?.id}
            >
              <CardModuleWrapper
                id={m.id!}
                dragHandleProps={provider.dragHandleProps}
              >
                <Mod fields={{ ...m.fields }} defaults={card.defaults} />
              </CardModuleWrapper>
            </CardModuleContext>
          </div>
        )}
      />
    );
  });
  return (
    <DragDropContext
      onDragEnd={(r) => {
        const from = r.source.index.toString();
        const to = r.destination?.index.toString();
        if (!to) return;

        // to aid transition animation needs to be set immediately
        const modules = Array.from(card.modules);
        const [removed] = modules.splice(Number(from), 1);
        modules.splice(Number(to), 0, removed as any);
        const newCard = { ...card, modules };
        setCard(newCard);

        // delayed
        //submit({ op: "move", from, to }, { method: "patch" });
      }}
    >
      <Droppable
        droppableId="drop"
        children={(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {parts}
            {provided.placeholder}
          </div>
        )}
      />
    </DragDropContext>
  );
};

const CardV2: React.FC = () => {
  const data = useLoaderData() as { card: Promise<Card> };
  return (
    <div className="flex items-center justify-center gap-4 md:my-4">
      <div className="relative w-full min-w-[360px] max-w-[414px]">
        <div
          className={cx(
            "flex h-full w-full flex-col",
            "bg-white shadow-lg md:rounded-lg",
            "transition-transform"
          )}
        >
          <Await resolve={data.card} children={<CardModules />} />
        </div>
      </div>
    </div>
  );
};

export default CardV2;
